@import 'variables';

.AccountsPage {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.AccountsPage-Title {
  font-size: 2.6em;

  @media (min-width: 768px) {
    font-size: 3em;
  }
}

.AccountsPage-Brand {
  display: none;

  @media (min-width: 768px) {
    background: $midnight;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 30vw;
  }

  @media (min-width: 992px) {
    width: 40vw;
  }

  .logo {
    max-width: 450px;

    @media (min-width: 768px) {
      width: 100%;
      margin-top: 10rem;
      padding: 0 2rem;
    }
  }
}

.AccountsPage-Accent {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;

  polygon {
    fill: $raspberry;

    @media (min-width: 768px) {
      display: block;
    }
  }
}

.AccountsPage-Body {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1rem;

  @media (min-width: 768px) {
    align-items: center;
    margin-top: 0;
    padding: 4rem;
  }

  @media (min-width: 992px) {
    padding: 6rem;
  }

  @media (min-width: 1200px) {
    padding: 8rem;
  }

  form {
    width: 100%;
    max-width: 500px;
  }

  input {
    width: 100%;
    line-height: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  .AccountsPage-Description {
    line-height: 1.6;
    font-size: 16px;
  }
}

.AccountsPage-Header {
  @media (min-width: 768px) {
    display: none;
  }
}
