@import 'variables';

@font-face {
  font-family: 'Campton';
  src: url('../fonts/campton_medium.otf');
}

@font-face {
  font-family: 'FreightText';
  src: url('../fonts/freight_text_book.otf');
}

html {
  box-sizing: border-box;
  color: $softblack;
  font-kerning: normal;
  font-family: Baskerville, 'Baskerville Old Face', 'Goudy Old Style', Garamond, 'Times New Roman',
    serif;
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-family: 'FreightText';
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

iframe {
  border: none;
}
