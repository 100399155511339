@import 'variables';

.CourseNav {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}

.CourseModule-Header {
  font-family: 'FreightText', serif;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 1.5rem;
}

.CourseModule.is-secondary {
  background: $raspberry;
  border-radius: 5px;
  margin: 1rem;
  overflow: hidden;

  .CourseNav-Header,
  .CourseNav-Lessons a {
    border-left: none;

    &.active {
      background: rgba(0, 0, 0, 0.15);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.CourseNav-Week {
  margin-bottom: 0.5rem;
}

.CourseNav-Header {
  display: block;
  padding: 1rem 2rem;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-left: 5px solid transparent;
  background: transparent;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.2);
  }

  &.active {
    background: rgba(255, 255, 255, 0.1);
    border-left-color: $raspberry;
  }

  &[disabled] {
    cursor: no-drop;
  }
}

.CourseNav-WeekNumber {
  text-transform: uppercase;
  font-family: 'Campton', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0 0 0.25rem 0;
  color: rgba(255, 255, 255, 0.7);
}

.CourseNav-WeekTitle {
  margin: 0;
}

.CourseNav-Lessons {
  margin: 0;
  padding: 0;
  font-size: 16px;
  list-style: none;

  a {
    transition: background 0.3s, border-left-color 0.3s;
    border-left: 5px solid transparent;
    padding: 1rem 2rem 1rem 2rem;
    display: block;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: baseline;

    &.active {
      background: rgba(255, 255, 255, 0.1);
      border-left-color: $raspberry;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.CourseNav-ComingSoon {
  font-size: 14px;
  font-style: italic;
}

.CourseNav-LessonNumber {
  display: block;
  font-family: 'Campton';
  font-size: 14px;
  margin-right: 1rem;
  letter-spacing: 1px;
  flex-basis: 46px;
  flex-shrink: 0;
  text-align: right;

  &:empty {
    flex-basis: 20px;
    margin-right: 0;
  }
}

.CourseNavigation {
  overflow: hidden;
  position: relative;
}

.CourseModule {
  background: $midnight;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate3d(100%, 0, 0);
  visibility: hidden;
  width: 100%;

  &.slide-enter {
    transition: transform ease-out 250ms;
    visibility: visible;
  }

  &.slide-enter,
  &.slide-enter-done {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  &.slide-exit {
    transition: transform ease-in 250ms;
    visibility: visible;
  }
}
