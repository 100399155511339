.CourseIndex-Logo {
  display: block;
  margin: 3rem auto;
  max-width: 40%;
}

.CourseIndex-Greeting {
  font-weight: 400;
  margin-bottom: 0;
}

.CourseIndex-Nav {
  margin: 2rem 0;
}

.CourseIndex-Body {
  margin: 0 auto;
  padding: 2rem 1rem 3rem;

  @media (min-width: 992px) {
    padding: 3rem;
  }

  @media (min-width: 1200px) {
    padding: 6rem 3rem;
    width: 960px;
  }
}

.CourseIndex-Title {
  font-family: 'FreightText', serif;
  font-size: 3rem;
  font-weight: normal;
  margin-top: 0;
}

.CourseIndex-Courses {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2rem;

  .CourseItem {
    flex: 1;
    margin-left: 2rem;
    max-width: 400px;
  }
}
