.Footer {
  background: rgba(255, 255, 255, 0.95);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.Footer-Item {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  width: 50%;
  max-width: 400px;
  font-size: 16px;
  transition: background 0.3s;

  &.Footer-Item--right {
    text-align: right;
    justify-content: flex-end;
  }

  &.Footer-Item--left {
    justify-content: flex-start;
  }
}

a.Footer-Item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.Footer-Item--left > .Footer-ItemBody {
  margin-left: 0.5rem;
}

.Footer-Item--right > .Footer-ItemBody {
  margin-right: 0.5rem;
}

.Footer-LessonNumber {
  font-family: 'Campton', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.Footer-Icon {
  flex-shrink: 0;
}

.Footer-LessonTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25vw;
  display: inline-block;

  @media (min-width: 992px) {
    white-space: normal;
    max-width: auto;
  }
}
