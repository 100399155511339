.Alert {
  font-style: italic;
}

.Alert-Error {
  color: #e22a16;
}

.Alert-Success {
  color: #2c5440;
}
