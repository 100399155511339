@import 'variables';

.CourseTitle {
  background: $raspberry;
  color: $white;
  height: calc(270px + env(safe-area-inset-top));
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  h1 {
    font-family: 'FreightText';
    font-weight: normal;
    margin: 1rem 2rem 2rem;
    font-size: 32px;
  }

  h2 {
    font-family: Campton, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.075em;
    margin: 2rem;
  }

  a {
    text-decoration: none;
  }

  &.gray {
    background: $gray-blue;
    color: $softblack;
  }
}

.BackToCourses {
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 1px;
  font-family: 'Campton', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.8);
  transition: background 0.3s;
  letter-spacing: 0.5px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 5px;
  }

  .gray & {
    color: $softblack;
  }
}
