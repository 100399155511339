@import 'variables';

$sidebar-width: 350px;
$sidebar-width-small: 250px;
$nav-animation: transform 0.3s ease-in-out;

.Layout {
  overflow-x: hidden;
}

.Layout-Nav {
  background: $midnight;
  color: #fff;
  left: 0;
  min-height: 100vh;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: $sidebar-width-small;
  z-index: 1;

  .navigating-enter &,
  .navigating-enter-done & {
    transition: opacity 0ms 5ms;
    pointer-events: all;
    position: absolute;
  }

  .navigating-enter-active &,
  .navigating-enter-done & {
    opacity: 1;
  }

  .navigating-exit & {
    transition: opacity 0ms 0.35s;
  }

  @media (min-width: 1200px) {
    &,
    .navigating-enter &,
    .navigating-enter-active &,
    .navigating-enter-done &,
    .navigating-exit & {
      opacity: 1;
      pointer-events: all;
      top: 0 !important;
      transform: translate3d(0, 0, 0);
      width: $sidebar-width;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.Layout-Translate {
  transform: translate3d(0, 0, 0);
  transition: $nav-animation;

  .navigating-enter-active &,
  .navigating-enter-done & {
    transform: translate3d($sidebar-width-small, 0, 0);
  }

  @media (min-width: 1200px) {
    &,
    .navigating-enter-active &,
    .navigating-enter-done & {
      transform: translate3d(0, 0, 0);
    }
  }
}

.Layout-Body {
  background: #fff;
  box-shadow: none;
  transition: $nav-animation, box-shadow 0.3s;
  min-height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 2;

  .navigating-enter &,
  .navigating-enter-done & {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: -10px 0 10px -8px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 1200px) {
    &,
    .navigating-enter &,
    .navigating-enter-active &,
    .navigating-enter-done & {
      padding-top: 0;
      margin-left: $sidebar-width;
      position: static;
      width: auto;
      transform: translate3d(0, 0, 0);
      box-shadow: none;
    }
  }
}

.Layout-NavToggle {
  align-items: center;
  position: absolute;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  height: 72px;
  justify-content: center;
  padding: 1rem;
  z-index: 1;
}

.Layout-Header {
  z-index: 5;

  @media (min-width: 1200px) {
    display: none;
  }
}

.Layout-Footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 3;

  @media (min-width: 1200px) {
    width: calc(100% - #{$sidebar-width});
  }
}

.Layout-Overlay {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;

  .navigating-enter &,
  .navigating-enter-done & {
    display: block;
  }

  @media (min-width: 1200px) {
    &,
    .navigating-enter &,
    .navigating-enter-active &,
    .navigating-enter-done & {
      display: none;
    }
  }
}
