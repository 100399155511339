@import 'variables';

.Page {
  font-size: 18px;
  margin: 0 auto;
  padding: 2rem 1rem 3rem;
  position: relative;

  @media (min-width: 992px) {
    font-size: 20px;
    padding: 5rem 9rem 6rem;
  }

  @media (min-width: 1200px) {
    padding: 6rem 9rem;
    width: 960px;
  }

  h1 {
    font-size: 2.6em;
    line-height: 1.3;
    margin: 0 0 -1.5rem -2rem;
    padding: 1rem 0 1rem 2rem;

    @media (min-width: 1200px) {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: '';
      width: 200px;
      height: 5px;
      margin: 2rem 0;
      background: $raspberry;
      display: block;
    }
  }

  h2 {
    margin-top: 3rem;

    @media (min-width: 992px) {
      margin-top: 4rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'FreightText', serif;
    font-weight: normal;
  }

  p,
  ul,
  ol {
    font-family: Baskerville;
    line-height: 1.6;
  }

  p iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto 2rem;
  }

  a {
    color: $raspberry;
    text-decoration: underline;
  }

  .Button {
    color: #fff;
    text-decoration: none;
  }
}

.Page-Label {
  padding: 1.5rem 0;
  font-style: italic;
  text-align: right;
  right: calc(100% - 5rem);
  max-width: 60px;

  @media (min-width: 1200px) {
    position: absolute;
  }
}
