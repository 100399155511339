@import 'variables';

.Button {
  background: $raspberry;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: 'Campton', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity 0.3s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background 0.15s ease-in-out;
  width: 100%;

  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: 0;
  }

  &:hover {
    background: darken($raspberry, 5%);
  }

  &[disabled] {
    opacity: 0.7;
  }

  &.Button--flex {
    align-items: center;
    display: inline-flex;
    width: auto;
  }

  .Button-Icon {
    margin-right: 0.5em;
  }

  &.Button--smaller {
    font-size: 14px;
  }

  p > &:only-child {
    display: flex;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
  }

  p > & + & {
    float: right;
  }
}
