@import 'variables';

$height-xs: calc(72px + env(safe-area-inset-top));

.Header {
  background: $midnight;
  color: #fff;
  height: $height-xs;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 5;

  .logo {
    padding: 1rem;
    max-height: 100%;
  }
}

.Header-Space {
  margin-top: $height-xs;
}

.Header-Pad {
  padding-top: $height-xs;
}
