@import 'variables';

.CourseItem {
  background: $gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;
  text-align: center;
}

.CourseItem-Title {
  font-family: 'FreightText', serif;
  font-weight: 400;
  font-size: 32px;
}

.CourseItem-Subtitle {
  font-family: Campton, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.CourseItem-Logo {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
}

.CourseItem-Img {
  max-height: 100%;
  max-width: 100%;
}

.CourseItem-Body {
  flex: 1 1 auto;
}

.CourseItem-Desc {
  line-height: 1.5;
  margin: 2rem 1rem;
}

.CourseItem-Footer {
  font-size: 16px;
  line-height: 1.5;
  margin: 1rem 0 0;
}

.CourseItem-Links {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
